module.exports = {
    asgardApiUrl: {
        name: 'ASGARD_API_URL'
    },
    
    apiUrl: {
        name: 'API_URL',
        defaultValue: "https://api.getmanfred.com"
    },

    appUrl: {
        name: 'AUTH0_BASE_URL'
    },

    auth0Audience: {
        name: 'AUTH0_AUDIENCE'
    },

    auth0Secret: {
        name: 'AUTH0_SECRET',
        hide: true
    },

    auth0ClientSecret: {
        name: 'AUTH0_CLIENT_SECRET',
        hide: true
    },
    
    auth0ClientId: {
        name: 'AUTH0_CLIENT_ID'
    },

    auth0IssuerBaseUrl: {
        name: 'AUTH0_ISSUER_BASE_URL'
    },

    segmentApiKey: {
        name: 'NEXT_PUBLIC_SEGMENT_API_KEY'
    },

    segmentCustomProxyUrl: {
        name: 'NEXT_PUBLIC_SEGMENT_CUSTOM_PROXY_URL'
    },

    segmentCustomCDN: {
        name: 'NEXT_PUBLIC_SEGMENT_CUSTOM_CDN'
    },

    manfrediteProfileBaseUrl: {
        name: 'NEXT_PUBLIC_MANFREDITE_PROFILE_URL'
    },

    flagsmithEnvironmentKey: {
        name: 'FLAGSMITH_ENVIRONMENT_KEY'
    },

    offerExampleOffer: {
        name: 'DEFAULT_OFFER_URL'
    }
};
